<template>
  <div>
    <category-list v-model="filters" />
    <course-list :courses="allCourses" :filters="filters" />
    <main-page-institute-list />
    <faq-list />
    <feedback/>
  </div>
</template>

<script>

import CourseList from "../components/course/CourseList.vue";
import MainPageInstituteList from "../components/MainPageInstituteList.vue";
import FaqList from "../components/FaqList.vue";
import Feedback from "../components/Feedback.vue";
import CategoryList from "../components/course/CategoryList.vue";
import {eventBus} from "../main";
import {mapGetters} from "vuex";
export default {
  name: "MainPage",
  components: {CategoryList, Feedback, FaqList, MainPageInstituteList, CourseList},
  data() {
    return {
      filters: {
        categories: [],
        title: null,
        activeTab: '1'
      }
    }
  },
  computed: mapGetters(['allCourses']),
  mounted() {
    eventBus.$on('filters:clear', () => {
      this.filters = {
        categories: [],
        title: null
      }
    })
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Главная',
    // all titles will be injected into this template
    titleTemplate: '%s | МИИГАиК'
  }
}
</script>