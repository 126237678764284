<template>
  <div class="about-page">
    <kinesis-container event="scroll" class="container">
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-first">
          <img src="../assets/images/kinesis-first.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-second">
          <img src="../assets/images/kinesis-second.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-third">
          <img src="../assets/images/kinesis-third.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-fourth">
          <img src="../assets/images/kinesis-fourth.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-five">
          <img src="../assets/images/kinesis-five.svg" />
        </div>
      </kinesis-element>
      <div class="about-page__wrapper">
        <h1 class="text-rothek font-104 mb-60">О проекте</h1>
        <svg class="about-page__wrapper-icons mb-7" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23 45.5C35.4264 45.5 45.5 35.4264 45.5 23C45.5 10.5736 35.4264 0.5 23 0.5C10.5736 0.5 0.5 10.5736 0.5 23C0.5 35.4264 10.5736 45.5 23 45.5ZM21.2682 31.1402L33.7682 16.1402L32.2318 14.8598L20.4328 29.0186L13.7071 22.2929L12.2929 23.7071L19.7929 31.2071L20.5672 31.9814L21.2682 31.1402Z" fill="#F7941E"/>
        </svg>
        <div class="mb-50" style="text-align: justify">
          <p><b>Московский государственный университет геодезии и картографии (МИИГАиК)</b> — базовая организация государств-участников Содружества Независимых Государств по подготовке кадров в области геодезии, картографии, кадастра и дистанционного зондирования Земли (Решение Совета глав правительств стран СНГ 21 мая 2010 г.), активно работающая над цифровым образовательным контентом.</p>
          <p><b>&laquo;Сетевой университет государств-участников СНГ по подготовке кадров в области геодезии, картографии, кадастра и дистанционного зондирования Земли&raquo;</b> – это проект по созданию консорциума образовательных и отраслевых организаций государств-участников СНГ по подготовке кадров в области геодезии, картографии, кадастра и дистанционного зондирования Земли.</p>
          <p>Наша методическая и информационно-образовательная платформа представляет программный комплекс со специализированным контентом для реализации данного проекта.</p>
        </div>
        <h2 class="text-rothek font-36 mb-40">Принципы реализации проекта</h2>
        <div class="font-24 line-30 font-16-md line-20-md mb-60 row">
          <div class="mb-24 col-md-12 col-lg-6">
            <div class="fill fill-grey fill-grey-sm">
              <div class="circle-number mb-20 bg-purple">1</div>
              <p class="text-rothek">
                Открытая образовательная платформа и доступное обучение в рамках проекта
              </p>
            </div>
          </div>
          <div class="mb-24 col-md-12 col-lg-6">
            <div class="fill fill-grey fill-grey-sm">
              <div class="circle-number mb-20 bg-green">2</div>
              <p class="text-rothek">
                Вовлечение в проект ведущих университетов и лучших преподавателей страны и мира
              </p>
            </div>
          </div>
          <div class="mb-24 col-md-12 col-lg-6">
            <div class="fill fill-grey fill-grey-sm">
              <div class="circle-number mb-20 bg-orange">3</div>
              <p class="text-rothek">
                Активная образовательная среда, использование современных технологий и методик
              </p>
            </div>
          </div>
          <div class="mb-24 col-md-12 col-lg-6">
            <div class="fill fill-grey fill-grey-sm">
              <div class="circle-number mb-20 bg-blue">4</div>
              <p class="text-rothek">
                Ориентированность части курсов на конкретных работодателей с возможностью последующего трудоустройства
              </p>
            </div>
          </div>
        </div>
      </div>
      <faq-list />
      <feedback/>
    </kinesis-container>
  </div>
</template>

<script>
import FaqList from "../components/FaqList.vue";
import Feedback from "../components/Feedback.vue";

export default {
  name: "AboutPage",
  components: {Feedback, FaqList}
}
</script>