<template>
  <div>
  </div>
</template>

<script>
import courseMixin from "../../mixin/courseMixin";

export default {
  name: "CourseInstitute",
  mixins: [courseMixin]
}
</script>

<style scoped>

</style>