<template>
  <div id="element">
    <template v-if="element">
      <base-video-player v-if="element.type === 'film'" :video="element.file" />
      <base-pdf-reader v-if="element.type === 'file-pdf-o'" :src="element.file.contentPath" />
      <base-test v-if="element.type === 'list'" />
      <base-homework
          v-if="element.type === 'home'"
          :loading="homeworkSaving"
          :status="element.elementResult.homework.status"
          :content="element.elementResult.homework.content"
          :attachments="element.elementResult.attachments"
          @draft="onHomeworkSave($event, true)"
          @submit="onHomeworkSave($event, false)"
      />
    </template>
    <skeleton v-else :lines="20" />
    <div v-if="element && element.description" class="mt-30 fill fill-sm fill-white" v-html="element.description"></div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import BaseVideoPlayer from "../components/element/BaseVideoPlayer.vue";
import Skeleton from "../components/Skeleton.vue";
import {eventBus} from "../main";
import BasePdfReader from "../components/element/BasePdfReader.vue";
import BaseTest from "../components/element/BaseTest.vue";
import BaseHomework from "../components/element/BaseHomework.vue";

export default {
  name: "ElementPage",
  components: {BaseHomework, BaseTest, BasePdfReader, BaseVideoPlayer, Skeleton},
  data() {
    return {
      calcHeight: null,
      homeworkSaving: false
    }
  },
  async mounted() {
    await this.getElement(this.$route.params.eIndex)
  },
  computed: {
    ...mapGetters(['element', 'module', 'course'])
  },
  methods: {
    ...mapActions(['getElement']),
    ...mapMutations(['setElement']),
    onHomeworkSave(data, isDraft = false) {
      if (this.homeworkSaving) {
        return;
      }

      this.homeworkSaving = true;
      this.axios
          .post(
              `element_results/${this.element.elementResult.id}`,
              {...data, isDraft}
          )
          .then(async () => {
            window.location.reload()
          })
          .finally(() => this.homeworkSaving = false)
    }
  },
  watch: {
    '$route.params.eIndex': {
      deep: true,
      handler: async function (val) {
        await this.setElement(null);
        setTimeout(() => {
          this.getElement(val)
        }, 500)
      }
    },
    'element.id'(val) {
      if(val) {
        setTimeout(() => {
          this.calcHeight = document.getElementById('element').offsetHeight
          eventBus.$emit('calcHeight', this.calcHeight)
          if(this.element && this.element.elementResult.status === 'locked') {
            this.$router.push('/course/' + this.course.id + '/module/1/element/1').catch(() => {})
          }
        }, 500)
      }
    }
  }
}
</script>

<style scoped>

</style>