<template>
  <div>
    <template v-if="course">
      <div class="fill bg-blue">
        <course-top-header :course="course" />
        <course-header :course="course" />
      </div>
      <router-view />
    </template>
    <div v-else>
      <skeleton :lines="7" />
      <skeleton :lines="20" style-class="mt-24" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CourseHeader from "../components/course/CourseHeader.vue";
import CourseTopHeader from "../components/course/CourseTopHeader.vue";
import Skeleton from "../components/Skeleton.vue";

export default {
  name: "CoursePage",
  components: {
    Skeleton, CourseTopHeader, CourseHeader
  },
  async mounted() {
    await this.getCourse(this.$route.params.id)
  },
  methods: {
    ...mapActions(['getCourse'])
  },
  computed: {
    ...mapGetters(['course'])
  },
  watch: {
    '$route.params.id'(val) {
      this.getCourse(val)
    }
  }
}
</script>