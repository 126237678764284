<template>
  <div class="fill fill-grey mt-24">
    <h2 class="text-rothek font-36 mb-20 mt-20">Авторы<br/> курса</h2>
    <el-row :gutter="20" class="mt-40">
      <el-col :lg="8" :md="8" :sm="12" :xs="24" v-for="tutor in course.courseTutors" :key="tutor.tutor.id">
        <div class="fill fill-sm bg-white pl-16 pb-12 pt-12 pr-24 d-flex mb-24">
          <tutor-avatar v-if="tutor.tutor" :photo="tutor.tutor.photo" />
          <tutor-data :tutor="tutor.tutor" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TutorAvatar from "./TutorAvatar.vue";
import TutorData from "./TutorData.vue";

export default {
  name: "CourseAuthorList",
  components: {TutorData, TutorAvatar},
  computed: {
    ...mapGetters(['course'])
  }
}
</script>

<style scoped>
</style>