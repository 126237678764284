<template>
  <div class="mobile-menu">
    <login-dialog v-model="showDialog" />
    <a href="#" v-if="!$auth.check()" @click="login" class="mr-20">Войти</a>
    <template v-else>
      <a class="mr-8" @click.prevent="$router.push('/me')" href="#">
        <svg class="non-transform" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="46" height="46" rx="23" fill="#ededed"/>
          <path d="M30.6634 30.6667V28.7501C30.6634 27.7334 30.2595 26.7584 29.5407 26.0395C28.8218 25.3206 27.8467 24.9167 26.8301 24.9167H19.1634C18.1467 24.9167 17.1717 25.3206 16.4528 26.0395C15.7339 26.7584 15.3301 27.7334 15.3301 28.7501V30.6667" stroke="#1F1F1F" stroke-width="1.94595" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22.9974 21.0834C25.1145 21.0834 26.8307 19.3672 26.8307 17.2501C26.8307 15.133 25.1145 13.4167 22.9974 13.4167C20.8803 13.4167 19.1641 15.133 19.1641 17.2501C19.1641 19.3672 20.8803 21.0834 22.9974 21.0834Z" stroke="#1F1F1F" stroke-width="1.94595" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>
    </template>
    <div id="menuToggle">
      <input v-model="menuOpen" type="checkbox" />
      <span></span>
      <span></span>
      <span style="margin: 0"></span>
      <ul id="menu">
        <li class="menu__item">
          <router-link to="/catalog">Программы</router-link>
        </li>
        <li class="menu__item">
          <router-link to="/catalog">Каталог курсов</router-link>
        </li>
        <li class="menu__item">
          <router-link active-class="active" to="/news">Новости</router-link>
        </li>
        <li class="menu__item">
          <router-link to="/about">О проекте</router-link>
        </li>
        <li class="menu__item">
          <a href="#" @click.prevent="$auth.logout()" class="mr-20">Выйти</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import LoginDialog from "./LoginDialog.vue";

export default {
  name: "MobileMenu",
  components: {LoginDialog},
  data() {
    return {
      menuOpen: false,
      showDialog: false
    }
  },
  methods: {
    login() {
      this.showDialog = true
      return false;
    }
  },
  watch:{
    '$route' (){
      this.menuOpen = false
    }
  },
}
</script>