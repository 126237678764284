<template>
  <div class="element_list">
    <div @click="toElement(module, element)" class="element_list__item" v-for="element in module.moduleElements" :key="element.id" active-class="active">
      <div class="d-flex align-items-center">
        <element-type :element="element" />
        <element-title :element="element" />
      </div>
      <element-status :locked="element.elementResult.status === 'locked' || module.isLocked" :element="element" />
    </div>
  </div>
</template>

<script>
import ElementType from "./ElementType.vue";
import ElementTitle from "./ElementTitle.vue";
import ElementStatus from "./ElementStatus.vue";
import {mapGetters} from "vuex";

export default {
  name: "ModuleElementList",
  components: {ElementStatus, ElementTitle, ElementType},
  props: {
    module: {
      type: Object,
      required: true
    }
  },
  methods: {
    toElement(module, element) {
      if(element.elementResult.status !== 'locked') {
        return this.$router.push('/course/' + this.course.id + '/module/' + module.position + '/element/' + element.position).catch(() => {})
      }
    }
  },
  computed: {
    ...mapGetters(['course'])
  }
}
</script>

<style lang="scss">
.element_list {
  &__item {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    background: #ededed;
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 22px;
    &.active {
      border: 3px solid #A5A5A5;
    }
  }
}
</style>