<template>
  <div class="fill fill-white">
    <el-spinner v-if="loading" />
    <div v-if="!loading">
      <div class="text-rothek font-25 mb-20">Ответ на домашнее задание</div>
      <textarea :disabled="canEdit" class="form-control mb-20" v-model="noteContent"></textarea>
      <div class="mb-50">
        <ul v-if="noteAttachments.length > 0" class="mb-2">
          <li v-for="file in noteAttachments" :key="file.id" class="mb-20">
            <span class="d-flex justify-content-between align-items-center">
              <a :href="axios.defaults.baseURL + file.path" target="_blank">{{ file.name }}</a>
              <div class="d-flex" v-if="!canEdit">
                <a href="javascript:void(0)" v-if="!file.deleted" class="badge badge-danger mr-20" @click="dropAttachment(file)">Удалить</a>
                <a href="javascript:void(0)"  v-if="file.deleted" class="badge badge-info" @click="restoreAttachment(file)">Восстановить</a>
              </div>
            </span>
          </li>
        </ul>

        <div class="text-center">
          <file-upload
              v-if="3 > validAttachmentsCount && status !== 'submitted' && !canEdit"
              :actionForUpload="this.axios.defaults.baseURL + 'files/upload'"
              :disableAccept="true"
              @input="onFileUpload($event)"
          >
            <button class="btn btn-sm btn-black">
              Выберите файл для загрузки <i class="fa fa-cloud-upload"></i>
            </button>
          </file-upload>
          <span class="font-weight-lighter small">(Вы можете прикрепить до 3х файлов)</span>
        </div>
      </div>
      <div class="mt-4">
        <div v-if="status === 'submitted'" class="font-weight-bold">Отправлено на проверку</div>
        <div v-if="status === 'checked'" class="font-weight-bold">Прошло проверку</div>
        <div class="d-flex justify-content-between" v-if="!canEdit">
          <button class="btn btn-sm btn-purple" @click="$emit('draft', prepareData())">Сохранить как черновик</button>
          <button class="btn btn-sm btn-black" @click="$emit('submit', prepareData())">Отправить на проверку</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from "../FileUpload";
export default {
  name: "BaseHomework",
  components: {
    FileUpload,
  },
  props: {
    loading: {
      default: false,
      type: Boolean
    },
    status: String,
    content: String,
    attachments: Array,
  },
  data() {
    return {
      noteAttachments: this.attachments
          ? this.attachments.map(a => {
            return {...a, deleted: false}
          })
          : [],
      noteContent: this.content
    }
  },
  computed: {
    validAttachmentsCount() {
      return this.noteAttachments.filter(a => !a.deleted).length;
    },
    canEdit() {
      return ['submitted', 'checked'].indexOf(this.status) > -1;
    }
  },
  methods: {
    prepareData() {
      return {
        content: this.noteContent,
        attachments: this.noteAttachments.filter(a => !a.deleted).map(a => a.id)
      }
    },
    onFileUpload(fileInfo) {
      this.noteAttachments.push({...fileInfo, deleted: false});
    },
    dropAttachment(attachment) {
      attachment.deleted = true;
    },
    restoreAttachment(attachment) {
      attachment.deleted = false;
    }
  }
}
</script>

<style scoped>

</style>
