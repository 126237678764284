<template>
  <div id="app" v-if="$auth.ready()">
    <div class="wrapper">
      <layout-header />
      <router-view></router-view>
    </div>
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from "./components/LayoutHeader.vue";
import LayoutFooter from "./components/LayoutFooter.vue";
import {mapActions} from "vuex";
export default {
  name: 'App',
  components: {
    LayoutFooter,
    LayoutHeader
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'МИ',
    // all titles will be injected into this template
    titleTemplate: 'Межвузовская площадка электронного образования | %s'
  },
  directives: {
    infocus: {
      isLiteral: true,
      inserted: (el, binding) => {
        let f = () => {
          let rect = el.getBoundingClientRect()
          let inView = (
              rect.width > 0 &&
              rect.height > 0 &&
              rect.top >= 0 &&
              rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          )
          if (inView) {
            el.classList.add(binding.value)
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
        f()
      }
    }
  },
  mounted() {
    this.getCourses()
    this.getCategories()
  },
  methods: {
    ...mapActions(['getCourses', 'getCategories'])
  }
}
</script>

<style lang="scss">
@import "assets/style/main";
$animationDuration: 1.2s;
@import "vue2-animate/src/sass/vue2-animate.scss";
#app {
  overflow-x: hidden;
}
</style>
