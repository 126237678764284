export default {
    methods: {
        clearStyle(text) {
            return text
                .replace(/style="[^"]*"/, "")
                .replace(/style="[^"]*"/, "")
                .replace(/style="[^"]*"/, "")
        },
        truncate(text, stop, clamp = '...') {
            return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
        }
    }
}